<template>
  <ib-dialog
    :title="$t('pages.users.inviteACollaborator')"
    :visible="visible"
    width="750px"
    top="8vh"
    @open="onOpen"
    @close="$emit('close')"
    @closed="onClosed"
  >
    <el-row>
      <el-col>
        <el-form
          ref="inviteForm"
          class="invite-form"
          :model="form"
          :rules="rules"
          @submit.prevent.native
        >
          <el-row>
            <el-col>
              <el-form-item prop="email">
                <el-input
                  v-model="form.email"
                  :placeholder="$t('pages.users.enterValidEmailAddress')"
                />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item
                prop="note"
                class="mb-0"
              >
                <el-input
                  v-model="form.note"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 3}"
                  :placeholder="$t('pages.users.invitationMessage')"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <!-- Access chooser -->
    <access-chooser class="mt-4 mb-4" />
    <!-- /Access chooser -->

    <!-- Idea access chooser -->
    <idea-access-chooser v-model="form.ideas" />
    <!-- /Idea access chooser -->

    <template #footer>
      <ib-divider class="mb-4" block />
      <ib-dialog-footer
        :loading="loading"
        :submit-btn-text="$t('pages.users.sendInvitation')"
        :disable-submit-btn="!hasOneOrMoreIdeasEnabled"
        @submit="sendInvitation"
        @cancel="$emit('close')"
      />
    </template>
  </ib-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AccessChooser from '@/views/Home/InvitedUsers/Components/AccessChooser'
import IdeaAccessChooser from '@/views/Home/InvitedUsers/Components/IdeaAccessChooser'
import UserRoleEnum from '@/constants/UserRoleEnum'
import rules from '@/views/Home/InvitedUsers/data/validationRules'

export default {
  name: 'DialogInviteCollaborator',

  components: {
    AccessChooser,
    IdeaAccessChooser
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    role: {
      type: Number,
      default: UserRoleEnum.GUEST
    }
  },

  data () {
    return {
      loading: false,
      form: {
        email: null,
        note: null,
        ideas: []
      },
      rules
    }
  },

  computed: {
    ...mapGetters('ideaCollection', [
      'getIdeas'
    ]),

    hasOneOrMoreIdeasEnabled () {
      return this.form.ideas.reduce((a, item) => {
        return a.concat(item.enable)
      }, []).some(item => {
        return item === true
      })
    },

    ideas () {
      return this.getIdeas.map(idea => {
        const { id, name } = idea

        return {
          id,
          name,
          role: this.role,
          enable: false
        }
      })
    }
  },

  methods: {
    ...mapActions('user/collaborators', ['inviteCollaborator']),

    onOpen () {
      if (this.$refs.inviteForm) {
        this.$refs.inviteForm.clearValidate()
      }

      this.form.ideas = this.ideas
    },

    onClosed () {
      this.form = {
        email: null,
        note: null,
        ideas: []
      }
    },

    sendInvitation () {
      this.$refs.inviteForm.validate((valid) => {
        if (!valid) return
        this.loading = true
        const ideas = this.form.ideas.filter(idea => idea.enable)
        const { email, note } = this.form

        this.inviteCollaborator({ email, note, ideas }).then((response) => {
          this.dialogInviteUserVisible = false
          this.$emit('close')
          this.loading = false

          switch (response.status) {
            case 201:
              this.$notify({ title: this.$t('success'), message: this.$t('pages.users.invitationSent'), type: 'success' })
              break
            case 202:
              this.$notify({ title: this.$t('success'), message: this.$t('pages.users.invitationSentNoEmail'), type: 'success' })
              break
            case 208:
              this.$notify({ title: this.$t('warning'), message: this.$t('pages.users.userHasAlreadyInvited'), type: 'warning' })
              break
          }
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>
