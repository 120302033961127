import businessGuideStepsEnum from '@/constants/businessGuideStepsEnum'

export default {
  concept: {
    id: businessGuideStepsEnum.CATEGORY_CONCEPT,
    name: 'pages.businessGuide.navigation.concept',
    icon: null,
    route: null,
    children: [
      {
        id: businessGuideStepsEnum.SECTION_IDEA,
        name: 'pages.businessGuide.navigation.idea',
        icon: 'ib-icon-idea',
        route: 'business-guide-concept-idea',
        completedStepName: 'ideaCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_PRODUCT,
        name: 'pages.businessGuide.navigation.product',
        icon: 'ib-icon-shipping-product',
        route: 'business-guide-concept-product',
        completedStepName: 'productCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_PROMOTION,
        name: 'pages.businessGuide.navigation.promotion',
        icon: 'ib-icon-commercial',
        route: 'business-guide-concept-promotion',
        completedStepName: 'promotionCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_DISTRIBUTION,
        name: 'pages.businessGuide.navigation.distribution',
        icon: 'ib-icon-in-transit',
        route: 'business-guide-concept-distribution',
        completedStepName: 'distributionCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_PARTNERS,
        name: 'pages.businessGuide.navigation.partners',
        icon: 'ib-icon-user-account',
        route: 'business-guide-concept-partners',
        completedStepName: 'partnerCompleted',
        completedStatus: true
      }
      // {
      //   id: null,
      //   name: i18n.t('pages.businessGuide.navigation.testing',
      //   icon: 'ib-icon-pass-fail',
      //   route: null,
      //   completedStepName: null,
      //   completedStatus: true
      // }
    ]
  },
  research: {
    id: businessGuideStepsEnum.CATEGORY_RESEARCH,
    name: 'pages.businessGuide.navigation.research',
    icon: null,
    route: null,
    children: [
      {
        id: businessGuideStepsEnum.SECTION_MARKET,
        name: 'pages.businessGuide.navigation.market',
        icon: 'ib-icon-basket',
        route: 'business-guide-research-market',
        completedStepName: 'marketCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_CUSTOMERS,
        name: 'pages.businessGuide.navigation.customer',
        icon: 'ib-icon-client-management',
        route: 'business-guide-research-customers',
        completedStepName: 'customersCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_COMPETITORS,
        name: 'pages.businessGuide.navigation.competitors',
        icon: 'ib-icon-conference',
        route: 'business-guide-research-competitors',
        completedStepName: 'competitorsCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_SWOT_ANALYSIS,
        name: 'pages.businessGuide.navigation.swotAnalysis',
        icon: 'ib-icon-grid-2',
        route: 'business-guide-research-swot-analysis',
        completedStepName: 'swotAnalysisCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_MARKET_POTENTIAL,
        name: 'pages.businessGuide.navigation.marketPotential',
        icon: 'ib-icon-account',
        route: 'business-guide-research-market-potential',
        completedStepName: 'marketPotentialCompleted',
        completedStatus: true
      }
      // {
      //   id: null,
      //   name: 'pages.businessGuide.navigation.comparison',
      //   icon: 'ib-icon-compare',
      //   route: null,
      //   completedStepName: null,
      //   completedStatus: true
      // }
    ]
  },
  setUp: {
    id: businessGuideStepsEnum.CATEGORY_SETUP,
    name: 'pages.businessGuide.navigation.setUp',
    icon: null,
    route: null,
    children: [
      {
        id: businessGuideStepsEnum.SECTION_STARTUP_COSTS,
        name: 'pages.businessGuide.navigation.startupCosts',
        icon: 'ib-icon-money-bag',
        route: 'business-guide-set-up-startup-costs',
        completedStepName: 'startupCostsCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_FINANCING,
        name: 'pages.businessGuide.navigation.financing',
        icon: 'ib-icon-money',
        route: 'business-guide-set-up-financing',
        completedStepName: 'financingCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_MANAGEMENT_TEAM,
        name: 'pages.businessGuide.navigation.managementTeam',
        icon: 'ib-icon-meeting-room',
        route: 'business-guide-set-up-management-team',
        completedStepName: 'managementTeamCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_STAFF_AND_PAYROLL,
        name: 'pages.businessGuide.navigation.staffAndPayroll',
        icon: 'ib-icon-money-transfer',
        route: 'business-guide-set-up-staff-and-payroll',
        completedStepName: 'employeesAndPayrollCompleted',
        completedStatus: true
      },
      {
        id: null,
        name: 'pages.businessGuide.navigation.ownership',
        icon: 'ib-icon-investment-portfolio',
        route: 'business-guide-set-up-ownership',
        completedStepName: 'ownershipCompleted',
        completedStatus: true
      }
      // {
      //   id: null,
      //   name: 'pages.businessGuide.navigation.checklist',
      //   icon: 'ib-icon-to-do',
      //   route: null,
      //   completedStepName: null,
      //   completedStatus: true
      // }
    ]
  },
  projection: {
    id: businessGuideStepsEnum.CATEGORY_PROJECTIONS,
    name: 'pages.businessGuide.navigation.projections',
    icon: null,
    route: null,
    children: [
      {
        id: businessGuideStepsEnum.SECTION_REVENUE_STREAMS,
        name: 'pages.businessGuide.navigation.revenueStreams',
        icon: 'ib-icon-get-revenue',
        route: 'business-guide-projections-revenue-streams',
        completedStepName: 'revenueStreamsCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_DIRECT_COSTS,
        name: 'pages.businessGuide.navigation.directCosts',
        icon: 'ib-icon-cost',
        route: 'business-guide-projections-direct-costs',
        completedStepName: 'directCostsCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_MARKETING_BUDGET,
        name: 'pages.businessGuide.navigation.marketingBudget',
        icon: 'ib-icon-transaction',
        route: 'business-guide-projections-marketing-budget',
        completedStepName: 'marketingBudgetCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_OTHER_OVERHEADS,
        name: 'pages.businessGuide.navigation.otherOverheads',
        icon: 'ib-icon-estimate',
        route: 'business-guide-projections-other-overheads',
        completedStepName: 'otherOverheadsCompleted',
        completedStatus: true
      },
      {
        id: businessGuideStepsEnum.SECTION_PROFIT_AND_CASH_FLOW,
        name: 'pages.businessGuide.navigation.profitAndCashFlow',
        icon: 'ib-icon-currency-exchange',
        route: 'business-guide-projections-profit-and-cash-flow',
        completedStepName: 'profitAndCashFlowCompleted',
        completedStatus: true
      }
      // {
      //   id: null,
      //   name: 'pages.businessGuide.navigation.industryStand',
      //   icon: 'ib-icon-spiral-bound-booklet',
      //   route: null,
      //   completedStepName: null,
      //   completedStatus: true
      // }
    ]
  }
  // validation: {
  //   id: null,
  //   name: 'pages.businessGuide.navigation.validation',
  //   icon: null,
  //   route: null,
  //   children: [
  //     {
  //       id: null,
  //       name: 'pages.businessGuide.navigation.ideaScore',
  //       icon: 'ib-icon-rating',
  //       route: 'validation-idea-score',
  //       completedStepName: null,
  //       completedStatus: false
  //     }
  //   ]
  // }
}
