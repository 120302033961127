<template>
  <ib-free-trial-service-provider v-slot="{freeTrial, isDangerBtn, goToPricingPage}">
    <div
      v-if="freeTrial"
      :class="{'is-danger-btn': isDangerBtn}"
      class="ib-free-trial-banner-container d-flex flex-column flex-md-row justify-content-md-center position-relative text-center cursor-pointer p-2"
      @click="goToPricingPage"
    >
      <h5
        :class="{'mr-md-2': isDangerBtn}"
        class="text text-white m-0"
      >
        <i
          v-if="isDangerBtn"
          class="el-icon-warning-outline font-weight-bold"
        />
        {{ $t('freeTrial.freeTrialWillExpire') }} <span v-text="freeTrial" />
      </h5>
      <h5 v-if="isDangerBtn" class="m-0 text-uppercase text-white pricing-text">
        {{ $t('freeTrial.goToPricingPage') }}
      </h5>
    </div>
  </ib-free-trial-service-provider>
</template>

<script>
import IbFreeTrialServiceProvider from '@/components/_v2/FreeTrial/IbFreeTrialServiceProvider'

export default {
  name: 'IbFreeTrialBanner',

  components: {
    IbFreeTrialServiceProvider
  }
}
</script>

<style scoped lang="scss">
.ib-free-trial-banner-container {
  background-color: $color-yellow;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 14%), 6px 0 6px 0 rgb(0 0 0 / 6%);
  z-index: 2000;

  &.is-danger-btn {
    background-color: $color-red;
  }

  .pricing-text {
    text-decoration: underline;
  }
}
</style>
